import { defineStore } from 'pinia'
import type {
  TrackingDeclarations,
  StatusCollection,
  MultiInstanceStatusCollection,
  StatusWithRefs,
} from './types'
import useWhitelist from './composables/use-whitelist'
import useStatusTracker from './composables/use-status-tracker'
import useStatusChanger from './composables/use-status-changer'
import useCollectionMaker from './composables/use-collection-maker'

const actionStatusStore = defineStore('action-status', () => {
  const { whitelist, whitelistActions } = useWhitelist()
  const { statuses, trackStatus } = useStatusTracker()
  const { setActionOk, setActionFailed } = useStatusChanger(statuses)

  // getter
  const actionsGetter = (
    scope: TrackingDeclarations
  ):
    | StatusCollection<StatusWithRefs>
    | MultiInstanceStatusCollection<StatusWithRefs> => {
    const actionAliases = Object.keys(scope)

    return actionAliases.reduce(
      (
        collection:
          | StatusCollection<StatusWithRefs>
          | MultiInstanceStatusCollection<StatusWithRefs>,
        alias
      ) => {
        const actionItem = scope[alias]
        const actionAlias =
          typeof actionItem === 'string' ? actionItem : actionItem.action

        collection[alias] = statuses.value[actionAlias] || {}
        return collection
      },
      {}
    )
  }

  function removeActions(actions: TrackingDeclarations) {
    const collection = useCollectionMaker(actions)

    collection.forEach((item) => {
      const { [item.action]: _, ...rest } = statuses.value
      statuses.value = rest
    })

    whitelist.value = whitelist.value.filter(
      (whitelistItem: any) =>
        !collection.some((item) => whitelistItem.action === item.action)
    )

    // for testing
    return whitelist
  }

  return {
    whitelist,
    whitelistActions,
    removeActions,

    statuses,
    trackStatus,

    setActionOk,
    setActionFailed,

    actionsGetter,
  }
})

export default actionStatusStore
