import api from './api'
import type {
  EditDeletePermission,
  JsonApiListResponse,
  JsonApiResponse,
} from './types'
import type { TeamMembership } from './role'

export type Team<L = TeamMembership> = {
  id: string
  name: string
  parent_team_id?: string
  parent_team?: Team
  team_memberships?: L[]
  permissions?: {
    actions?: EditDeletePermission
    team_memberships?: {
      create: boolean
      edit: boolean
      delete: boolean
    }
  }
}

export type TeamPayload = Omit<Team, 'id'>

export function listTeams(): Promise<JsonApiListResponse<Team>> {
  return api
    .url(
      `/api/v1/teams?meta[permissions]=actions&sort=name&include=parent_team`
    )
    .get()
    .json()
}

export function listTeamsWithTeamMemberships(): Promise<
  JsonApiListResponse<Team>
> {
  return api
    .url(
      `/api/v1/teams?sort=name&include=team_memberships&meta[permissions]=team_memberships`
    )
    .get()
    .json()
}

export function getTeam(teamId: string): Promise<JsonApiResponse<Team>> {
  return api
    .url(
      `/api/v1/teams/${teamId}?meta[permissions]=actions,team_memberships&include=parent_team`
    )
    .get()
    .json()
}

export function createTeam(
  payload: TeamPayload
): Promise<JsonApiResponse<Team>> {
  return api
    .url(`/api/v1/teams`)
    .post({
      data: {
        type: 'team',
        attributes: {
          name: payload.name,
          parent_team_id: payload.parent_team_id,
        },
      },
    })
    .json()
}

export async function updateTeam(
  teamId: string,
  payload: Partial<TeamPayload>
): Promise<JsonApiResponse<Team>> {
  return api
    .url(`/api/v1/teams/${teamId}`)
    .put({
      data: {
        type: 'team',
        attributes: {
          name: payload.name,
          parent_team_id: payload.parent_team_id,
        },
      },
    })
    .json()
}

export function deleteTeam(teamId: string): Promise<JsonApiResponse<Team>> {
  return api.url(`/api/v1/teams/${teamId}`).delete().json()
}
