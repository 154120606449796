<script setup lang="ts">
import { toRef } from 'vue'
import type { ChatMember } from '@papershift/api/src/chat'
import type { Role } from '@papershift/api/src/role'
import { useI18n } from '@papershift/locale/src/i18n'
import useAuthStore from '@/stores/auth/auth.store'

const props = defineProps<{
  members: ChatMember[]
  canEdit: boolean
}>()

defineEmits<{
  'edit-click': [id: string]
}>()

const { t } = useI18n()

const authStore = useAuthStore()

const members = toRef(props, 'members')

const columns = [
  { key: 'name', label: t('table.columns.name') },
  { key: 'roles', label: t('table.columns.role') },
  { key: 'edit' },
]

function getRoleNames(roles: Role[]) {
  return roles.map((role) => role.name).join(', ')
}
</script>

<template>
  <Table
    :columns="columns"
    :data="members"
    :sticky-header="false"
    caption="t('table.caption')"
    class="mt-4"
  >
    <template #roles="{ row }">
      {{ getRoleNames(row.roles) }}
    </template>
    <template v-if="canEdit" #edit="{ row }">
      <div class="text-right font-medium">
        <button
          :disabled="row.id === authStore.user?.id"
          class="text-pink-600 hover:text-pink-900 disabled:cursor-not-allowed disabled:text-gray-400"
          @click="$emit('edit-click', row.id)"
        >
          {{ t('table.actions.edit') }}
          <span class="sr-only">, {{ row.name }}</span>
        </button>
      </div>
    </template>
  </Table>
</template>

<style scoped>
:deep(tbody) {
  @apply bg-white;
}
</style>

/* v8 ignore start */
<i18n locale="en">
table:
  caption: Chat Members
  columns:
    name: Name
    role: Roles
  actions:
    edit: Edit
</i18n>

<i18n locale="de">
table:
  caption: Chat Mitglieder
  columns:
    name: Name
    role: Rollen
  actions:
    edit: Bearbeiten
</i18n>
/* v8 ignore stop */
