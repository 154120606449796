<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed z-50 inset-0 sm:inset-4 flex items-end px-4 py-6 sm:items-end sm:p-6"
  >
    <TransitionGroup
      :appear="true"
      tag="div"
      class="flex w-full flex-col items-center space-y-4 sm:items-end"
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <slot />
    </TransitionGroup>
  </div>
</template>
