import type { Ref } from 'vue'
import type {
  Status,
  MultiInstanceStatus,
  StatusCollection,
  MultiInstanceStatusCollection,
  ActionObject,
  TrackingDeclarationValue,
  StatusWithRefs,
} from '../types'

export default function useStatusChanger(
  statuses: Ref<
    | StatusCollection<StatusWithRefs>
    | MultiInstanceStatusCollection<StatusWithRefs>
  >
) {
  function setActionOk(
    action: ActionObject,
    options: TrackingDeclarationValue
  ) {
    const status: Partial<Status> = {
      isLoading: false,
      isOk: true,
      isFailed: false,
      error: null,
    }
    let ref = statuses.value[action.type]

    if (options.trackByKey) {
      const payload = action.payload as Record<string, any>
      const payloadKey = payload[options.trackByKey].toString()
      const selectedStatus = statuses.value[
        action.type
      ] as MultiInstanceStatus<StatusWithRefs>

      ref = selectedStatus.instances[payloadKey]
    }
    Object.assign(ref, status)
  }

  function setActionFailed(
    action: ActionObject,
    options: TrackingDeclarationValue,
    error: unknown
  ) {
    const status: Partial<Status> = {
      isLoading: false,
      isOk: false,
      isFailed: true,
      response: null,
      error,
    }
    let ref = statuses.value[action.type]

    if (options.trackByKey) {
      const payload = action.payload as Record<string, any>
      const payloadKey = payload[options.trackByKey].toString()
      const selectedStatus = statuses.value[
        action.type
      ] as MultiInstanceStatus<StatusWithRefs>

      ref = selectedStatus.instances[payloadKey]
    }
    Object.assign(ref, status)
  }

  return { setActionOk, setActionFailed }
}
