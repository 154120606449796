import type { ActionObject, ApiValidationError } from '../types'
import { type Ref, computed } from 'vue'

export default function useMessagesGetters(
  statuses: Ref<Record<string, any>>,
  action: ActionObject
) {
  const hasErrorMessages = computed(() => {
    const status = statuses.value[action.type]

    if (!status.error) return false

    try {
      const json = JSON.parse(status.error.message)
      return json.errors.length > 0
    } catch {
      return false
    }
  })

  const errorMessages = computed(() => {
    if (!hasErrorMessages.value) return []

    const status = statuses.value[action.type]
    const json = JSON.parse(status.error.message)

    return json.errors
      .filter((err: ApiValidationError) => err.pointer === 'base')
      .map((err: ApiValidationError) => err.title)
  })

  const validationMessages = computed(() => {
    if (!hasErrorMessages.value) return new Map()

    const status = statuses.value[action.type]
    const json = JSON.parse(status.error.message)

    return json.errors.reduce(
      (allErrors: Map<string, string>, err: ApiValidationError) => {
        if (err.pointer !== 'base') {
          allErrors.set(err.pointer, err.title)
        }
        return allErrors
      },
      new Map()
    )
  })

  return { hasErrorMessages, errorMessages, validationMessages }
}
