<script setup lang="ts">
import { computed } from 'vue'
import {
  XCircleIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from './icons'

type MessageType = 'error' | 'warning' | 'success' | 'info'

const props = defineProps<{
  id: string
  type: MessageType
  title: string
}>()

defineSlots<{
  default: any
}>()

const classes = computed(() => {
  const c = {
    wrapper: '',
    title: '',
    body: '',
  }

  switch (props.type) {
    case 'error':
      c.wrapper = 'bg-pink-50'
      c.title = 'text-pink-800'
      c.body = 'text-pink-900'
      break
    case 'warning':
      c.wrapper = 'bg-yellow-50'
      c.title = 'text-yellow-800'
      c.body = 'text-yellow-700'
      break
    case 'success':
      c.wrapper = 'bg-green-50'
      c.title = 'text-green-800'
      c.body = 'text-green-700'
      break
    case 'info':
      c.wrapper = 'bg-blue-50'
      c.title = 'text-blue-800'
      c.body = 'text-blue-700'
      break
  }
  return c
})
</script>

<template>
  <div :id="id" class="rounded-md p-4" :class="classes.wrapper">
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon
          v-if="type === 'error'"
          class="h-5 w-5 text-pink-400"
          aria-hidden="true"
        />
        <ExclamationTriangleIcon
          v-if="type === 'warning'"
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
        <CheckCircleIcon
          v-if="type === 'success'"
          class="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
        <InformationCircleIcon
          v-if="type === 'info'"
          class="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </div>

      <div class="ml-3">
        <h3 class="text-sm font-medium" :class="classes.title">{{ title }}</h3>
        <div class="mt-2 text-sm" :class="classes.body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
