import api from './api'
import type {
  JsonApiListResponse,
  JsonApiResponse,
  EditDeletePermission,
} from './types'
import type { Role } from './role'
import type { User } from './user'

export type ChatContextType = 'onboarding' | 'offboarding'

export type Chat = {
  id: string
  context_id: string
  context_type: ChatContextType
  subject: string
  permissions?: {
    actions?: EditDeletePermission
    chat_membership?: {
      manage: boolean
    }
  }
}

export type ChatMessage = {
  id: string
  author_id: string
  chat_id: string
  content: string
  created_at: string
}

export type ChatMessageWithAuthor = ChatMessage & {
  author: User
}

export type ChatMember = {
  id: string
  email: string
  name: string
  roles?: Role[]
}

export function listChatsByContext(
  contextId: string,
  contextType: ChatContextType
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url(
      `/api/v1/chats?filter[context_id]=eq:${contextId}&filter[context_type]=eq:${contextType}&meta[permissions]=chat_membership`
    )
    .get()
    .json()
}

export function listMessagesByChat(
  chatId: string
): Promise<JsonApiListResponse<ChatMessageWithAuthor>> {
  return api
    .url(`/api/v1/chats/${chatId}/messages`)
    .query({
      include: 'author',
    })
    .get()
    .json()
}

export function createChatMessage(
  chatId: string,
  content: string
): Promise<JsonApiResponse<ChatMessage>> {
  return api
    .url(`/api/v1/chats/${chatId}/messages`)
    .post({
      data: {
        type: 'message',
        attributes: {
          content,
        },
      },
    })
    .json()
}

export function listChatMembers(
  chatId: string
): Promise<JsonApiListResponse<ChatMember>> {
  return api.url(`/api/v1/chats/${chatId}/members?include=roles`).get().json()
}
