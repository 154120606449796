import { defineStore } from 'pinia'
import { ref } from 'vue'
import useAuthStore from '../auth/auth.store'
import * as api from '@papershift/api/src/display-settings'
import { switchLocale } from '@papershift/locale/src/i18n'

const displaySettingsStore = defineStore(
  'displaySettings',
  () => {
    const authStore = useAuthStore()

    const settings = ref<Partial<api.DisplaySettings>>({})
    const accountSettings = ref<Partial<api.DisplaySettings>>({})

    function $resetState() {
      settings.value = {}
      accountSettings.value = {}
    }

    async function updateDisplaySettings(
      payload: Partial<api.DisplaySettings>
    ) {
      const userId = authStore.user?.id

      if (!userId) throw new Error('Invalid user ID')

      await api.updateDisplaySettings(userId, payload)
    }

    async function fetchDisplaySettings(abortController?: AbortController) {
      const userId = authStore.user?.id

      if (!userId) throw new Error('Invalid user ID')

      const { data } = await api.getDisplaySettings(userId, abortController)
      settings.value = data.attributes

      applyDisplaySettings()
    }

    function applyDisplaySettings() {
      if (settings.value.language) {
        switchLocale(settings.value.language)
      }
    }

    async function fetchAccountDisplaySettings() {
      const { data } = await api.getAccountDisplaySettings()
      accountSettings.value = data.attributes
    }

    async function updateAccountDisplaySettings(
      payload: Partial<api.DisplaySettings>
    ) {
      await api.updateAccountDisplaySettings(payload)
    }

    return {
      $resetState,
      updateDisplaySettings,
      updateAccountDisplaySettings,
      fetchDisplaySettings,
      fetchAccountDisplaySettings,

      settings,
      accountSettings,
    }
  },
  {
    persist: {
      paths: ['settings'],
    },
  }
)

export default displaySettingsStore
