export type FilterItem = {
  key: string
  value: string
  label?: string
  operator?: FilterOperator
}

export enum FilterOperator {
  EQ = 'eq',
  NEQ = '!eq',
  IN = 'in',
  NIN = '!in',
  CT = 'ct',
}

export function transformQueryFilters(
  filters: FilterItem[]
): Record<string, string> {
  return Object.fromEntries(
    filters.map((item) => {
      const fields = item.key
        .split('.')
        .map((field) => `[${field}]`)
        .join('')

      const operator = item.operator ? `${item.operator}:` : ''

      return [`filter${fields}`, `${operator}${item.value}`]
    })
  )
}
