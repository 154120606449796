import { createPinia, type StoreGeneric } from 'pinia'
import persistedstatePlugin from 'pinia-plugin-persistedstate'
import makeActionStatusHandler from '@papershift/action-status/src/action-handler'

const pinia = createPinia()

export const stores = new Set<StoreGeneric>()

pinia.use(persistedstatePlugin)
pinia.use(({ store }) => {
  stores.add(store)
  store.$onAction(makeActionStatusHandler(pinia))
})

export function resetStoreStates() {
  for (const store of stores) {
    if (typeof store.$resetState === 'function') store.$resetState()
  }
}

export function getStoreById(id: string) {
  return [...stores].find((store) => store.$id === id)
}

export default pinia
