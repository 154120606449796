import { type Pinia, setActivePinia } from 'pinia'
import type {
  ActionObject,
  TrackingDeclarationValue,
  ActionHandlerArg,
  MultiInstanceStatus,
  StatusWithRefs,
} from './types'
import useActionStatusStore from './store'

export default function makeActionStatusHandler(activePinia: Pinia) {
  setActivePinia(activePinia)

  return function actionHandler({
    name,
    args,
    after,
    onError,
  }: ActionHandlerArg) {
    const { trackStatus, setActionOk, setActionFailed, ...state } =
      useActionStatusStore()
    const action: ActionObject = { type: name, payload: args }

    const trackingOptions = state.whitelist.find(
      (item: TrackingDeclarationValue) => item.action === action.type
    )
    if (trackingOptions) {
      trackStatus(action, trackingOptions)
    }

    after(
      makeOnDispatchHandler(
        action,
        state.statuses[action.type],
        state.whitelist,
        setActionOk
      )
    )

    onError(
      makeOnErrorHandler(
        action,
        state.statuses[action.type],
        state.whitelist,
        setActionFailed
      )
    )
  }
}

export function makeOnDispatchHandler(
  action: ActionObject,
  actionStatus: StatusWithRefs | MultiInstanceStatus<StatusWithRefs> | null,
  whitelist: TrackingDeclarationValue[],
  setActionOk: (a: ActionObject, o: TrackingDeclarationValue) => void
) {
  return () => {
    if (actionStatus) {
      const trackingOptions = whitelist.find(
        (item: TrackingDeclarationValue) => item.action === action.type
      )
      if (trackingOptions) {
        setActionOk(action, trackingOptions)
      }
    }
  }
}

export function makeOnErrorHandler(
  action: ActionObject,
  actionStatus: StatusWithRefs | MultiInstanceStatus<StatusWithRefs> | null,
  whitelist: TrackingDeclarationValue[],
  setActionFailed: (
    a: ActionObject,
    o: TrackingDeclarationValue,
    e: unknown
  ) => void
) {
  return (error: unknown) => {
    if (actionStatus) {
      const trackingOptions = whitelist.find(
        (item: TrackingDeclarationValue) => item.action === action.type
      )
      if (trackingOptions) {
        setActionFailed(action, trackingOptions, error)
      }
    }
  }
}
