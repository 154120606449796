import { cloneVNode, h, render } from 'vue'
import Confirmation from './Confirmation.vue'

export async function confirm(
  message: string,
  buttonLabel: string,
  title = ''
) {
  const elem = getElem()

  return new Promise<boolean>((resolve) => {
    const dialog = h(Confirmation, {
      isActive: true,
      title,
      message,
      buttonLabel,
      onConfirm: () => {
        hideDialog()
        resolve(true)
      },
      onClose: () => {
        hideDialog()
        resolve(false)
      },
    })
    const hideDialog = () =>
      render(
        cloneVNode(dialog, {
          isActive: false,
          onRemoved: () => {
            render(null, elem)
          },
        }),
        elem
      )

    render(dialog, elem)
  })
}

function getElem() {
  const id = 'confirmation'
  let elem = document.getElementById(id)

  if (!elem) {
    elem = document.createElement('div')
    elem.setAttribute('id', id)
    document.body.appendChild(elem)
  }

  return elem
}
