import {
  listRoleTemplates,
  type RoleTemplate,
} from '@papershift/api/src/template'
import { computed, ref } from 'vue'

type CompactRole = {
  id: string
  name: string
  description: string
}

export default function useRoleTemplates() {
  const roleTemplates = ref<RoleTemplate[]>([])

  const roleTemplatesCompact = computed<CompactRole[]>(() =>
    roleTemplates.value.map((template: RoleTemplate) => {
      return {
        id: template.id,
        name: template.data.attributes.name,
        scope: template.data.attributes.scope,
        description: template.data.attributes.description,
      }
    })
  )

  async function fetchRoleTemplates() {
    const { data } = await listRoleTemplates()
    roleTemplates.value = data
  }

  function getRoleTemplatePayload(id: string) {
    const template = roleTemplates.value.find((template) => template.id === id)
    return template && { template_id: template.id, ...template.data.attributes }
  }

  return {
    roleTemplatesCompact,
    fetchRoleTemplates,
    getRoleTemplatePayload,
  }
}
