<script setup lang="ts">
import { ref, computed, toRef } from 'vue'
import { type RuleExpression, Field, ErrorMessage } from 'vee-validate'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import { useFloating, autoUpdate, flip, size } from '@floating-ui/vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon, XMarkIcon } from './icons'
import isMatch from 'lodash/isMatch'
import isPlainObject from 'lodash/isPlainObject'
import type { FieldAppearance } from './types'

export type Option<T = unknown> = {
  value: T
  label: string
}

const { t } = i18n.global

addMessages({
  en: {
    placeholder: 'Select option | Select options',
    noOptions: 'No options available',
  },
  de: {
    placeholder: 'Option auswählen | Optionen auswählen',
    noOptions: 'Keine Optionen verfügbar',
  },
})

const props = withDefaults(
  defineProps<{
    id: string
    appearance?: FieldAppearance
    label: string
    options: Option[]
    modelValue: Option | Option[] | null
    clearButton?: boolean
    multiple?: boolean
    tabindex?: number
    disabled?: boolean
    placeholder?: string
    validationRules?: RuleExpression<any>
  }>(),
  { clearButton: true }
)

const enhancedOptions = computed(() => {
  if (props.options.length === 0) {
    return [{ value: null, label: t('noOptions'), disabled: true }]
  }
  return props.options.map((option) => ({ ...option, disabled: false }))
})

defineEmits<{
  'update:model-value': [value: typeof props.modelValue]
}>()

const model = toRef(props, 'modelValue')

function compareFunc(a: Option, b: Option) {
  if (!a || !b) {
    return false
  }

  if (isPlainObject(a.value) && isPlainObject(b.value)) {
    return isMatch(a.value!, b.value!)
  }

  return a.value === b.value
}

const buttonClass = computed(() => {
  if (props.appearance === 'borderless') {
    return 'pl-0 focus:ring-0 hover:bg-gray-100'
  }
  return 'pl-3 ring-1 focus:ring-2 shadow-sm bg-white'
})
const displayValue = computed(() => {
  if (Array.isArray(model.value)) {
    return model.value.map((o) => o.label).join(', ')
  }
  return model.value?.label
})

const floatingReference = ref(null)
const floatingElem = ref(null)

const { floatingStyles } = useFloating(floatingReference, floatingElem, {
  transform: false,
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    size({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          width: `${rects.reference.width}px`,
        })
      },
    }),
  ],
})
</script>

<template>
  <Listbox
    as="div"
    :by="compareFunc"
    :id="id"
    :multiple="multiple"
    :disabled="disabled"
    :model-value="model"
    @update:model-value="$emit('update:model-value', $event)"
    v-slot="{ open }"
  >
    <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </ListboxLabel>

    <div class="relative mt-1">
      <Field as="span" :name="id" :model-value="model" :rules="validationRules">
        <ListboxButton
          ref="floatingReference"
          :tabindex="tabindex"
          class="relative w-full cursor-default rounded-md py-1.5 pr-16 text-left text-gray-900 ring-inset ring-gray-300 focus:outline-none focus:ring-slate-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
          :class="buttonClass"
        >
          <span v-if="displayValue" class="block truncate">
            {{ displayValue }}
          </span>
          <span v-else class="block text-gray-500">
            {{ placeholder ?? t('placeholder', multiple ? 2 : 1) }}
          </span>

          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            :class="appearance === 'borderless' ? 'invisible' : 'visible'"
          >
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          <button
            v-if="clearButton && displayValue"
            class="absolute inset-y-0 right-6 flex items-center"
            type="button"
            @click="$emit('update:model-value', multiple ? [] : null)"
          >
            <XMarkIcon class="h-5 w-5 mr-2 text-gray-400 cursor-pointer" />
          </button>
        </ListboxButton>
      </Field>

      <ErrorMessage :name="id" class="text-sm text-pink-800" />

      <teleport to="body">
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            v-show="open"
            ref="floatingElem"
            :static="true"
            :style="floatingStyles"
            class="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-neutral-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              as="template"
              v-for="(option, index) in enhancedOptions"
              :key="index"
              :value="option"
              :disabled="option.disabled"
              v-slot="{ active, selected }"
            >
              <li
                :class="[
                  active ? 'bg-pink-600 text-white' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ option.label }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-pink-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </teleport>
    </div>
  </Listbox>
</template>
