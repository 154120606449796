import { createApp } from 'vue'

import App from './App.vue'
import router from './routes'
import store from './stores'
import i18n from '@papershift/locale/src/i18n'
import { getYupLocale } from '@papershift/locale/src/yup'
import * as yup from 'yup'

import './assets/main.css'

const app = createApp(App)

yup.setLocale(getYupLocale())

app.use(store)
app.use(router)
app.use(i18n)

app.mount('#app')

// exported for tests
export default app
