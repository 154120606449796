export function nameToInitials(fullName?: string): string | null {
  if (!fullName) {
    return null
  }

  return fullName
    .split(' ')
    .slice(0, 2)
    .map((namePart) => namePart[0])
    .join('')
}
