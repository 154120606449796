<script setup lang="ts">
import lodashDebounce from 'lodash/debounce'
import { toRef, ref, computed } from 'vue'
import { CheckIcon, ChevronDownIcon, XMarkIcon } from './icons'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import { useFloating, autoUpdate, flip, size } from '@floating-ui/vue'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import type { Status } from '@papershift/action-status/src/types'
import { ErrorMessage, Field, type RuleExpression } from 'vee-validate'
import type { FieldAppearance } from './types'

export type Option = {
  value: string
  label: string
}

const { t } = i18n.global

addMessages({
  en: {
    nothing_found: 'Nothing found',
    type_to_search: 'Type to search',
  },
  de: {
    nothing_found: 'Nichts gefunden',
    type_to_search: 'Tippe zum Suchen',
  },
})

const props = withDefaults(
  defineProps<{
    id: string
    appearance?: FieldAppearance
    label: string
    options: Option[]
    defaultOptions?: Option[]
    modelValue: Option | Option[] | null
    actionStatus?: Partial<Status>
    debounce?: number
    tabindex?: number
    placeholder?: string
    validationRules?: RuleExpression<any>
  }>(),
  {
    actionStatus: () => ({ isLoading: false }),
    debounce: 500,
    defaultOptions: () => [],
  }
)

const emit = defineEmits<{
  'update:model-value': [value: Option | null]
  input: [value: string]
}>()

const value = toRef(props, 'modelValue')
const floatingReference = ref(null)
const floatingElem = ref(null)
const isMulti = computed(() => Array.isArray(value.value))
const query = ref('')
const focused = ref(false)
const hasNoResults = computed(
  () => props.options.length === 0 && query.value.length > 0
)
const displayedOptions = computed(() => {
  if (props.options.length > 0) {
    return props.options
  } else if (hasNoResults.value) {
    return []
  }

  return props.defaultOptions
})

const { floatingStyles } = useFloating(floatingReference, floatingElem, {
  transform: false,
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    size({
      apply({ rects, elements }) {
        Object.assign(elements.floating.style, {
          width: `${rects.reference.width}px`,
        })
      },
    }),
  ],
})

const buttonClass = computed(() => {
  if (props.appearance === 'borderless') {
    return 'pl-0 focus:ring-0 hover:bg-gray-100'
  }
  return 'pl-3 ring-1 focus:ring-2 shadow-sm bg-white'
})

function displayValue(item: unknown) {
  if (isMulti.value) {
    const items = item as Option[]
    return items?.map((option) => option.label).join(', ')
  }
  return (item as Option)?.label
}

const handleInput = lodashDebounce(
  (value) => {
    query.value = value
    emit('input', value)
  },
  props.debounce,
  {
    maxWait: 1000,
  }
)

function handleFocus() {
  if (props.defaultOptions && props.defaultOptions.length > 0) {
    focused.value = true
  }
}
</script>

<template>
  <Combobox
    :id="id"
    as="div"
    :model-value="value"
    @update:model-value="$emit('update:model-value', $event)"
    v-slot="{ open }"
    :multiple="isMulti"
  >
    <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </ComboboxLabel>

    <div class="relative mt-1">
      <Field
        as="span"
        :name="id"
        :model-value="displayValue(value)"
        :rules="validationRules"
      >
        <!-- data-1p-ignore attr is for disabling 1Password's autofill icon -->
        <ComboboxInput
          ref="floatingReference"
          :tabindex="tabindex"
          :display-value="displayValue"
          :placeholder="placeholder ?? t('type_to_search')"
          class="w-full rounded-md border-0 bg-white py-1.5 pr-8 text-gray-900 ring-inset ring-gray-300 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
          :class="buttonClass"
          @change="handleInput($event.target.value)"
          autocomplete="off"
          data-1p-ignore
          @focus="handleFocus"
          @blur="focused = false"
        />
        <div class="absolute inset-y-0 right-0 flex items-center">
          <button
            v-if="value"
            type="button"
            @click="$emit('update:model-value', null)"
          >
            <XMarkIcon class="h-5 w-5 mr-2 text-gray-400 cursor-pointer" />
          </button>
          <ComboboxButton
            class="rounded-r-md pr-2 focus:outline-none"
            :class="appearance === 'borderless' ? 'invisible' : 'visible'"
          >
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
      </Field>

      <teleport to="body">
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ComboboxOptions
            v-show="
              (open || focused) &&
              !actionStatus.isLoading &&
              (displayedOptions.length > 0 || hasNoResults)
            "
            ref="floatingElem"
            :static="true"
            :style="floatingStyles"
            class="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-neutral-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <div
              v-if="hasNoResults"
              class="relative cursor-default select-none px-4 py-2 text-gray-700"
            >
              {{ t('nothing_found') }}
            </div>

            <ComboboxOption
              as="template"
              v-for="option in displayedOptions"
              :key="option.value"
              :value="option"
              v-slot="{ active, selected }"
            >
              <li
                :class="[
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-pink-600 text-white' : 'text-gray-900',
                ]"
              >
                <span :class="['block truncate', selected && 'font-semibold']">
                  {{ option.label }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                    active ? 'text-white' : 'text-pink-600',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </transition>
      </teleport>
    </div>

    <ErrorMessage :name="id" class="text-sm text-pink-800" />
  </Combobox>
</template>
