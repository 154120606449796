import get from 'lodash/get'
import useAuthStore from '@/stores/auth/auth.store'
import useAccountStore from '@/stores/account/account.store'
import useUserStore from '@/stores/user/user.store'
import { nextTick } from 'vue'
import { showLoading } from '@papershift/loading/src/loading'
import type {
  NavigationGuardWithThis,
  RouteLocationNormalized,
} from 'vue-router'

export const accountGuard: NavigationGuardWithThis<void> = (to, from, next) => {
  if (to.name !== 'switch-account') {
    return next()
  }

  const authStore = useAuthStore()
  const accountId = to.params.accountId as string

  try {
    authStore.switchAccount(accountId)
    next(from)
  } catch (e) {
    next()
  }
}

export const authGuard: NavigationGuardWithThis<void> = async (to, _, next) => {
  const authStore = useAuthStore()

  showLoading()

  if (to.meta.cleanSession && authStore.user) {
    await authStore.signoutUser()
    return next()
  }

  const authRequired = to.meta.authRequired !== false

  // setting currentUser happens in store creation,
  // but it is done in nextTick, so we need to align with that here
  await nextTick()

  if (authRequired && !authStore.user) {
    return next({ name: 'auth.signin' })
  }

  if (!authRequired && authStore.user) {
    return next({ name: 'employees' })
  }

  return next()
}

export const accountPermissionGuard: NavigationGuardWithThis<void> = async (
  to,
  from,
  next
) => {
  if (typeof to.meta.accountPermission === 'string') {
    const authStore = useAuthStore()
    const accountStore = useAccountStore()

    // fetch only when not coming from account settings,
    // i.e. first visit to settings page
    if (!String(from.name).startsWith('settings')) {
      await accountStore.fetchCurrentAccountSettingsPermissions()
    }

    if (!get(authStore.account?.permissions, to.meta.accountPermission)) {
      return next('/')
    }
  }

  return next()
}

export async function profileGuard(to: RouteLocationNormalized) {
  if (to.name !== 'profile' && to.name !== 'my-profile') {
    return true
  }

  const authStore = useAuthStore()

  // in case of own profile, redirect to onboarding if there is one running
  if (to.name === 'my-profile' || to.params.userId === authStore.user?.id) {
    const userStore = useUserStore()

    await userStore.fetchProfileUser(authStore.user!.id)

    if (userStore.profileUser?.profilePermissions.onboarding.view) {
      return { name: `${String(to.name)}.onboarding`, params: to.params }
    }
  }

  return {
    name: `${String(to.name)}.personnel-file.general`,
    params: to.params,
  }
}
