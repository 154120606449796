import { createRouter, createWebHistory } from 'vue-router'
import type { Paths } from 'type-fest'
import type { AccountPermissions } from '@papershift/api/src/account'
import { hideLoading } from '@papershift/loading/src/loading'
import {
  accountGuard,
  authGuard,
  accountPermissionGuard,
  profileGuard,
} from './guards'
import type { BoardingType } from '@papershift/api/src/boarding'

declare module 'vue-router' {
  interface RouteMeta {
    accountPermission?: Exclude<
      Paths<AccountPermissions>,
      Extract<keyof AccountPermissions, any>
    >
  }
}

function profileRoutes(path: string, namePrefix: string) {
  return {
    path,
    name: namePrefix,
    children: [
      {
        path: 'onboarding',
        name: `${namePrefix}.onboarding`,
        redirect: { name: `${namePrefix}.onboarding.welcome` },
        children: [
          {
            path: 'welcome',
            name: `${namePrefix}.onboarding.welcome`,
            component: () =>
              import('../views/ProfileOnboardingWelcomeView.vue'),
          },
          {
            path: 'tasks',
            name: `${namePrefix}.onboarding.tasks`,
            component: () => import('../views/ProfileOnboardingTasksView.vue'),
          },
        ],
      },
      {
        path: 'personnel-file',
        name: `${namePrefix}.personnel-file`,
        redirect: { name: `${namePrefix}.personnel-file.general` },
        children: [
          {
            path: 'general',
            name: `${namePrefix}.personnel-file.general`,
            component: () => import('../views/ProfileGeneralView.vue'),
          },
          {
            path: 'master-data',
            name: `${namePrefix}.personnel-file.master-data`,
            component: () => import('../views/ProfileMasterDataView.vue'),
          },
          {
            path: 'documents',
            name: `${namePrefix}.personnel-file.documents`,
            component: () => import('../views/ProfileDocumentsView.vue'),
          },
        ],
      },
      {
        path: 'teams',
        name: `${namePrefix}.teams`,
        component: () => import('../views/ProfileTeamsView.vue'),
      },
      {
        path: 'settings',
        name: `${namePrefix}.settings`,
        redirect: { name: `${namePrefix}.settings.display` },
        children: [
          {
            path: 'display',
            name: `${namePrefix}.settings.display`,
            component: () => import('../views/ProfileDisplaySettingsView.vue'),
          },
          {
            path: 'credentials',
            name: `${namePrefix}.settings.credentials`,
            component: () => import('../views/ProfileCredentialsView.vue'),
          },
        ],
      },
    ],
  }
}

function settingsBoardingRoutes(boardingType: BoardingType) {
  return {
    path: boardingType,
    name: `settings.${boardingType}`,
    redirect: { name: `settings.${boardingType}.stages` },
    children: [
      {
        path: 'stages',
        name: `settings.${boardingType}.stages`,
        component: () => import('../views/SettingsBoardingStagesView.vue'),
        props: { boardingType },
        meta: {
          // TODO: dynamic string not working well with accountPermission type here
          accountPermission: `${boardingType}_stages.view` as any,
        },
      },
      {
        path: 'templates',
        name: `settings.${boardingType}.templates`,
        component: () => import('../views/SettingsBoardingTemplatesView.vue'),
        props: { boardingType },
        meta: {
          accountPermission: `${boardingType}_templates.view` as any,
        },
      },
      {
        path: 'templates/:boardingTemplateId',
        name: `settings.${boardingType}.templates.tasks`,
        component: () => import('../views/SettingsBoardingTemplateView.vue'),
        meta: {
          accountPermission: `${boardingType}_templates.view` as any,
        },
      },
    ],
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'employees' },
    },
    {
      path: '/chats',
      name: 'chats',
      component: () => import('../views/ChatsView.vue'),
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('../views/EmployeesView.vue'),
    },
    {
      path: '/onboardings',
      name: 'onboardings',
      component: () => import('../views/BoardingsView.vue'),
      props: { boardingType: 'onboarding' },
      meta: {
        accountPermission: 'onboarding.view',
      },
    },
    {
      path: '/onboardings/:boardingId',
      name: 'onboarding.view',
      component: () => import('../views/EmployeeBoardingView.vue'),
    },
    {
      path: '/offboardings',
      name: 'offboardings',
      component: () => import('../views/BoardingsView.vue'),
      props: { boardingType: 'offboarding' },
      meta: {
        permission: 'offboarding.view',
      },
    },
    {
      path: '/offboardings/:boardingId',
      name: 'offboarding.view',
      component: () => import('../views/EmployeeBoardingView.vue'),
    },
    profileRoutes('/employees/:userId', 'profile'),
    profileRoutes('/my-profile', 'my-profile'),
    {
      path: '/settings',
      name: 'settings',
      redirect: { name: 'settings.general' },
      children: [
        {
          path: 'general',
          name: 'settings.general',
          component: () => import('../views/SettingsGeneralView.vue'),
          meta: {
            accountPermission: 'general.view',
          },
        },
        {
          path: 'organization',
          name: 'settings.organization',
          redirect: { name: 'settings.organization.users' },
          children: [
            {
              path: 'users',
              name: 'settings.organization.users',
              component: () => import('../views/SettingsUsersView.vue'),
              meta: {
                accountPermission: 'organization_users.view',
              },
            },
            {
              path: 'teams',
              name: 'settings.organization.teams',
              component: () => import('../views/SettingsTeamsView.vue'),
              meta: {
                accountPermission: 'organization_teams.view',
              },
            },
            {
              path: 'teams/:teamId',
              name: 'settings.organization.teams.view',
              component: () => import('../views/SettingsTeamView.vue'),
              meta: {
                accountPermission: 'organization_teams.view',
              },
            },
            {
              path: 'roles',
              name: 'settings.organization.roles',
              component: () => import('../views/SettingsRolesView.vue'),
              meta: {
                accountPermission: 'organization_roles.view',
              },
            },
            {
              path: 'roles/:roleId',
              name: 'settings.organization.roles.rights',
              component: () => import('../views/SettingsRoleView.vue'),
              meta: {
                accountPermission: 'organization_roles.view',
              },
            },
          ],
        },
        {
          path: 'fields',
          name: 'settings.fields',
          component: () => import('../views/SettingsFieldsView.vue'),
          meta: {
            accountPermission: 'fields.view',
          },
        },
        {
          path: 'display',
          name: 'settings.display',
          component: () => import('../views/SettingsDisplayView.vue'),
          meta: {
            accountPermission: 'appearance.view',
          },
        },
        settingsBoardingRoutes('onboarding'),
        settingsBoardingRoutes('offboarding'),
        {
          path: 'billing',
          name: 'settings.billing',
          redirect: { name: 'settings.billing.billing-information' },
          children: [
            {
              path: 'billing-information',
              name: 'settings.billing.billing-information',
              component: () =>
                import('../views/SettingsBillingInformationView.vue'),
              meta: {
                accountPermission: 'billing.view',
              },
            },
            {
              path: 'payment-methods',
              name: 'settings.billing.payment-methods',
              component: () =>
                import('../views/SettingsBillingPaymentMethodsView.vue'),
              meta: {
                accountPermission: 'billing.view',
              },
            },
            {
              path: 'subscription',
              name: 'settings.billing.subscription',
              component: () =>
                import('../views/SettingsBillingSubscriptionView.vue'),
              meta: {
                accountPermission: 'billing.view',
              },
            },
            {
              path: 'invoices',
              name: 'settings.billing.invoices',
              component: () =>
                import('../views/SettingsBillingInvoicesView.vue'),
              meta: {
                accountPermission: 'billing.view',
              },
            },
          ],
        },
        {
          path: 'legal',
          name: 'settings.legal',
          redirect: { name: 'settings.legal.agreements' },
          children: [
            {
              path: 'agreements',
              name: 'settings.legal.agreements',
              component: () =>
                import('../views/SettingsLegalAgreementsView.vue'),
              meta: {
                accountPermission: 'legal.view',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/new-account',
      name: 'new-account',
      component: () => import('../views/NewAccountView.vue'),
    },
    {
      path: '/switch-account/:accountId',
      name: 'switch-account',
      component: () => import('../views/NotFoundView.vue'),
    },
    {
      path: '/register',
      name: 'auth.register',
      component: () => import('../views/RegisterView.vue'),
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/signin',
      name: 'auth.signin',
      component: () => import('../views/SigninView.vue'),
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/forgot-password',
      name: 'auth.forgot-password',
      component: () => import('../views/ForgotPasswordView.vue'),
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/change-password/:token',
      name: 'auth.change-password',
      component: () => import('../views/ChangePasswordView.vue'),
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/join/:invite_token',
      name: 'auth.join',
      component: () => import('../views/JoinView.vue'),
      meta: {
        cleanSession: true,
        authRequired: false,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFoundView.vue'),
      meta: {
        authRequired: false,
      },
    },
  ],
})

router.beforeEach(accountGuard)
router.beforeEach(authGuard)
router.beforeEach(accountPermissionGuard)
router.beforeEach(profileGuard)

router.afterEach(hideLoading)

export default router
