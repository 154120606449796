import { ref, type VNode } from 'vue'
import * as yup from 'yup'

export type ValidationStatic = {
  schema: Record<string, yup.Schema>
}
export type ValidationLazy = {
  lazy: true
  schema: (obj: yup.AnyObjectSchema) => Record<string, yup.Schema>
}

export default function useValidationSchema(
  vNodes: VNode[],
  validationParam?: ValidationStatic | ValidationLazy
) {
  const validation = ref<ValidationStatic | ValidationLazy | undefined>(
    validationParam
  )
  const validationSchema = ref<yup.ObjectSchema<any> | undefined>()

  if (validation.value) {
    const labels = getComponentLabels(vNodes)

    if ('lazy' in validation.value) {
      // @ts-ignore yup doesn't export Lazy type: https://github.com/jquense/yup/issues/1954
      validationSchema.value = yup.lazy((obj) => {
        return getSchemaWithLabels(
          (validation.value as ValidationLazy).schema(obj),
          labels
        )
      })
    } else {
      validationSchema.value = getSchemaWithLabels(
        validation.value.schema,
        labels
      )
    }
  }

  return {
    validationSchema,
  }
}

function getSchemaWithLabels(
  schema: Record<string, yup.Schema>,
  labels: Map<string, string>
) {
  labels.forEach((label, key) => {
    if (key in schema) {
      schema[key] = schema[key].label(label)
    }
  })

  return yup.object(schema)
}

function getComponentLabels(vNodes: VNode[]) {
  const labels = new Map<string, string>()

  function add(node: any) {
    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        add(child)
      }
    } else if (node.props?.id && node.props?.label) {
      labels.set(node.props.id, node.props.label)
    }
  }

  vNodes.forEach((node) => {
    add(node)
  })

  return labels
}
