import { ref } from 'vue'
import type { TrackingDeclarationValue, TrackingDeclarations } from '../types'
import useCollectionMaker from './use-collection-maker'

export default function useWhitelist() {
  const whitelist = ref<TrackingDeclarationValue[]>([])

  function whitelistActions(actions: TrackingDeclarations) {
    whitelist.value = whitelist.value.concat(useCollectionMaker(actions))
  }

  return { whitelist, whitelistActions }
}
