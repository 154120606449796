import i18n, { addMessages } from './i18n'

const { t } = i18n.global

export function getYupLocale() {
  type Param = { path: string }

  return {
    string: {
      min: ({ path, min }: Param & { min: number }) =>
        t('validation.min', [path, min]),
      email: ({ path }: Param) => t('validation.email', [path]),
    },
    mixed: {
      required: ({ path }: Param) => t('validation.required', [path]),
    },
  }
}

export const messages = {
  en: {
    validation: {
      required: '{0} is required',
      email: '{0} is not a valid email address',
      min: '{0} must be at least {1} characters',
    },
  },
  de: {
    validation: {
      required: '{0} ist notwendig ',
      email: '{0} ist ungültig',
      min: '{0} muss mindestens {1} Zeichen lang sein',
    },
  },
}

addMessages(messages)
