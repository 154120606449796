import { computed, type Ref, ref } from 'vue'
import useUserStore from '@/stores/user/user.store'
import useActionTracker from '@papershift/action-status/src/action-tracker'
import type { User } from '@papershift/api/src/user'
import type { Option } from '@papershift/ui/src/Autocomplete.vue'
import {
  type FilterItem,
  FilterOperator,
} from '@papershift/api/src/filter-utils'

/**
 * The search criteria for searching users. Currently only one criterion is supported.
 *
 * @param teamId team id into which the searched user can be added
 */
type UserSearchCriteria = {
  teamId?: Ref<string>
}

/**
 * Composable for searching users, depending on different criteria.
 *
 * @param userSearchCriteria the search criteria
 */
export default function useUserSearch(
  userSearchCriteria: UserSearchCriteria = {}
) {
  const userStore = useUserStore()
  const users = ref<Option[]>([])
  const selected = ref<Option | null>(null)

  const $actions = useActionTracker({
    getAvailableUsers: 'getAvailableUsers',
  })

  /**
   * Searches for users based on the given keyword.
   *
   * @param keyword the phrase to search for
   */
  async function search(keyword: string) {
    if (!keyword) {
      users.value = []
      return
    }

    const filters: FilterItem[] = [
      { key: 'name', operator: FilterOperator.CT, value: keyword },
    ]

    if (userSearchCriteria?.teamId) {
      filters.push({
        key: 'teams_id',
        operator: FilterOperator.NEQ,
        value: userSearchCriteria.teamId.value,
      })
    }

    const response = await userStore.getAvailableUsers(filters)

    users.value = response.map((user: User) => ({
      value: user.id,
      label: user.name,
    }))
  }

  /**
   * Sets the selected user.
   *
   * @param user the user as an option
   */
  function setSelected(user: Option | null) {
    selected.value = user
  }

  /**
   * Async function that removes the selected user and resets the search results.
   */
  async function removeSelected() {
    setSelected(null)
    await search('')
  }

  return {
    searchUsers: search,
    selectedUser: selected,
    setSelectedUser: setSelected,
    removeSelectedUser: removeSelected,
    searchActionStatus: computed(() => $actions.getAvailableUsers),
    users,
  }
}
