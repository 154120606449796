import cloneDeep from 'lodash/cloneDeep'
import { ref } from 'vue'
import type {
  StatusWithRefs,
  MultiInstanceStatus,
  StatusCollection,
  MultiInstanceStatusCollection,
  ActionObject,
  TrackingDeclarationValue,
} from '../types'
import useMessagesGetters from './use-messages-getters'

export default function useStatusTracker() {
  const statuses = ref<
    | StatusCollection<StatusWithRefs>
    | MultiInstanceStatusCollection<StatusWithRefs>
  >({})

  function trackStatus(
    action: ActionObject,
    options: TrackingDeclarationValue
  ) {
    const { hasErrorMessages, errorMessages, validationMessages } =
      useMessagesGetters(statuses, action)

    const status: StatusWithRefs = {
      isLoading: true,
      isOk: null,
      isFailed: null,
      response: null,
      error: null,
      payload: null,
      hasErrorMessages,
      errorMessages,
      validationMessages,
    }

    // store payload with tracked action if it's opted in
    if (options.includePayload) {
      status.payload = cloneDeep(action.payload) as Record<string, unknown>
    }

    // multi-instance mode
    if (options.trackByKey) {
      if (!statuses.value[action.type]) {
        statuses.value[action.type] = { instances: {} }
      }

      const multiStatuses = statuses.value[
        action.type
      ] as MultiInstanceStatus<StatusWithRefs>

      if (typeof action.payload === 'object' && action.payload != null) {
        // at this point we know that action.payload is an object
        const actionObject = action as ActionObject<Record<string, unknown>>

        // convertion to any is safe here because of next if statement (toString)
        const payloadKeyValue = actionObject.payload[options.trackByKey] as any

        // make sure selected key value is valid object key
        if (payloadKeyValue && payloadKeyValue.toString().length > 0) {
          multiStatuses.instances[payloadKeyValue] = status
        }
      }
    } else {
      statuses.value[action.type] = status
    }
  }

  return { statuses, trackStatus }
}
