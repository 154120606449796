import type { State, RecordIndexMap } from './types'
import { getRef } from './utils'

export function isRecordTypeIndexed(state: State, type: string): boolean {
  return Boolean(state.recordsIndexes?.[type])
}

// creates index in the state for given `type` if not existing and returns it.
// indexes are saved in Map object. They are not meant to be reactive, because
// it's managed manually via jsonapi utils
export function createOrGetIndex(state: State, type: string): RecordIndexMap {
  if (!state.recordsIndexes) {
    state.recordsIndexes = {}
  }
  if (!state.recordsIndexes[type]) {
    state.recordsIndexes[type] = new Map()
  }

  return state.recordsIndexes[type]
}

// return index of a record for given type and id. Returns -1 if not existing
export function getRecordIndex(state: State, type: string, id: string): number {
  const indexes = createOrGetIndex(state, type)
  const recordIndex = indexes.get(id)

  if (recordIndex == null) {
    return -1
  }
  return recordIndex
}

// changes index of a record for given type and id into specified `index` param
// passing null as `index` deletes index of that record
export function reindexSingle(
  state: State,
  type: string,
  id: string,
  index: number | null
) {
  const indexes = createOrGetIndex(state, type)

  if (index == null) {
    indexes.delete(id)
  } else {
    indexes.set(id, index)
  }
}

// runs reindexSingle() for all records starting from `startIndex` and onwards
export function reindexMultiple(
  state: State,
  type: string,
  startIndex: number
) {
  const stateRecords = getRef(state, type)

  for (let i = startIndex; i < stateRecords.value.length; ++i) {
    const r = stateRecords.value[i]
    reindexSingle(state, type, r.id, i)
  }
}

// removes all indexes of given type
export function clearIndex(state: State, type: string) {
  if (isRecordTypeIndexed(state, type)) {
    // @ts-ignore
    state.recordsIndexes[type].clear()
  }
}
