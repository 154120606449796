import { ref, watch, nextTick, type Ref } from 'vue'

// Manages modal state
//
// if `data` params is provided, it will watch
// and open modal when it's available
export default function useModalToggler(data?: Ref<any>) {
  const isModalActive = ref(false)
  const onCloseListener = {
    handler: () => {},
  }

  // this watcher opens modal when the data is available
  // and stops watching after that
  function setupWatcher() {
    if (!data) return

    const unwatch = watch(
      () => data.value,
      () => {
        nextTick(() => {
          if (data.value) {
            toggleModal(true)
            unwatch()
          }
        })
      },
      { immediate: true }
    )
  }

  function toggleModal(open: boolean) {
    isModalActive.value = open
  }

  function modalRemovedHandler() {
    // call onModalClose hook for cleanup
    onCloseListener.handler()

    // start watching for data again
    // after modal is closed
    setupWatcher()
  }

  // watch for data and open modal when it's available
  setupWatcher()

  return {
    isModalActive,
    toggleModal,

    // modalRemovedHandler is a callback
    // to be used as Modal component's @removed event handler
    modalRemovedHandler,

    // onModalClose hook is called when modal is closed
    // so that parent component can do cleanup
    onModalClose(handler: () => void) {
      onCloseListener.handler = handler
    },
  }
}
