<script setup lang="ts">
import noop from 'lodash/noop'
import { RouterView } from 'vue-router'
import ChatView from '@/views/ChatView.vue'

// we need some dummy JS code to get sourcemaps generated
// https://github.com/cenfun/monocart-reporter/issues/33#issuecomment-1594033026
noop()
</script>

<template>
  <RouterView />
  <ChatView>
    <template #message="{ message, isFollowUp }">
      <ChatMessage :message="message" :is-follow-up="isFollowUp" />
    </template>
  </ChatView>
</template>
