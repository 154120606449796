<script setup lang="ts">
import { UserIcon } from '@papershift/ui/src/icons'
import noop from 'lodash/noop'

defineProps<{
  imageUrl?: string | null
  initials?: string | null
}>()

// we need some dummy JS code to get sourcemaps generated
// https://github.com/cenfun/monocart-reporter/issues/33#issuecomment-1594033026
noop()
</script>

<template>
  <img v-if="imageUrl" :src="imageUrl" alt="" class="rounded-full" />
  <div
    v-else
    class="rounded-full bg-gray-200 text-gray-500 flex items-center justify-center"
  >
    <p v-if="initials" class="text-sm">{{ initials }}</p>
    <UserIcon v-else class="text-xl" />
  </div>
</template>
