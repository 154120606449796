import {
  type Component,
  type ComputedRef,
  ref,
  defineComponent,
  createApp,
  h,
} from 'vue'
import NotificationList from './NotificationList.vue'
import Notification, { type Props } from './Notification.vue'

let instance: Component | null = null
const notifications = ref<Props[]>([])

type Params = {
  type?: Props['type']
  title: Props['title'] | ComputedRef<Props['title']>
  message: Props['message'] | ComputedRef<Props['message']>
}

// adds a new notification to the array and activates it
export function notify(params: Params) {
  setup()

  const id = Math.random().toString(36).substring(7)
  const type = params.type ?? 'success'

  notifications.value.unshift({
    id,
    type,
    // @ts-ignore ComputedRef is unwrapped once added to Ref<Props[]>, so this is misleading error
    title: params.title,
    // @ts-ignore ComputedRef is unwrapped once added to Ref<Props[]>, so this is misleading error
    message: params.message,
  })

  setTimeout(() => {
    removeNotification(id)
  }, 3 * 1000)
}

// sets up the instance and adds it to the DOM
function setup() {
  if (document.getElementById('notifications')) {
    return
  }

  instance = defineComponent({
    render: () =>
      h(NotificationList, () =>
        notifications.value.map((item: Props) =>
          h(Notification, {
            ...item,
            key: item.id,
            onClose: () => removeNotification(item.id),
          })
        )
      ),
  })

  const elem = document.createElement('div')
  elem.setAttribute('id', 'notifications')
  document.body.appendChild(elem)

  const app = createApp(instance)
  app.mount('#notifications')
}

// removes the notification from the array
function removeNotification(id: string) {
  notifications.value = notifications.value.filter(
    (item: Props) => item.id !== id
  )
}
