import merge from 'lodash/merge'
import { defineStore } from 'pinia'
import { resetStoreStates } from '../index'
import { ref, nextTick, computed } from 'vue'
import {
  type RegistrationParams,
  type ResetPasswordParams,
  type UpdatePasswordParams,
  type AuthResponse,
  register,
  signin,
  signout,
  resetPassword,
  updatePassword,
  type SigninParams,
} from '@papershift/api/src/auth'
import type {
  Account,
  AccountPermissionPaths,
} from '@papershift/api/src/account'
import { errorCatchers } from '@papershift/api/src/api'
import router from '@/routes'
import useUserStore from '@/stores/user/user.store'
import useDisplaySettingsStore from '@/stores/display-settings/display-settings.store'
import useAccountStore from '@/stores/account/account.store'
import useInvite from './composables/use-invite'
import useChatStore from '@/stores/chat/chat.store'
import get from 'lodash/get'

const authStore = defineStore(
  'auth',
  () => {
    const accountStore = useAccountStore()
    const authToken = ref<string | null>(null)

    const accounts = computed({
      get: () => accountStore.accounts,
      set: (data: Account[]) => accountStore.setAccounts(data),
    })
    const lastSelectedUserId = ref()
    const selectedUserId = ref()

    const currentAccount = computed<Account | null>({
      get: () => {
        const selectedAccount = accounts.value.find(
          (acc) => acc.user?.id === selectedUserId.value
        )
        return selectedAccount ?? null
      },

      set: (data) => {
        const selectedAccount = accounts.value.find(
          (acc) => acc.user?.id === selectedUserId.value
        )
        if (selectedAccount) {
          merge(selectedAccount, data)
        }
      },
    })

    const currentUser = computed(() => currentAccount.value?.user ?? null)

    const userStore = useUserStore()
    const displaySettingsStore = useDisplaySettingsStore()
    const chatStore = useChatStore()
    const { getInvitedUser, acceptInvite } = useInvite()

    const abortController = new AbortController()

    function $resetState() {
      authToken.value = null
      lastSelectedUserId.value = ''
      selectedUserId.value = ''
    }

    async function registerUser(payload: RegistrationParams) {
      const response = await register(payload)
      postSignin(response)
    }

    async function joinUser(
      inviteToken: string,
      userEmail: string,
      userPassword: string
    ) {
      const response = await acceptInvite(inviteToken, userEmail, userPassword)
      postSignin(response)
    }

    async function signinUser(payload: SigninParams) {
      const response = await signin(payload)
      postSignin(response)
    }

    async function signoutUser() {
      await signout()
      resetStoreStates()
    }

    async function fetchUser() {
      const userId = currentUser.value?.id
      if (!userId) throw new Error('Invalid user ID')

      currentAccount.value = {
        user: await userStore.getUser(userId),
      } as Account
    }

    function resetUserPassword(payload: ResetPasswordParams) {
      return resetPassword(payload)
    }

    function updateUserPassword(payload: UpdatePasswordParams) {
      return updatePassword(payload)
    }

    function postSignin(response: AuthResponse) {
      authToken.value = response.authToken
      accounts.value = response.data.attributes.accounts

      switchAccount(response.data.attributes.last_used_account_id)
    }

    function switchAccount(accountId: string) {
      // there is a request on store creation, which uses
      // previous selectedUserId, so we need to abort it
      abortController.abort()

      const selectedAccount = accounts.value.find((acc) => acc.id === accountId)
      selectedUserId.value = selectedAccount?.user?.id

      // given that accountId param is valid, once selectedUserId prop is set,
      // currentAccount computed prop will be updated
      if (!currentAccount.value) throw new Error('Invalid account ID')

      nextTick(() => {
        // this returns the account with object level permissions
        accountStore.setLastUsedAccount(accountId).then((account) => {
          // adds more data to currentAccount, does not replace it, see currentAccount setter
          currentAccount.value = account

          // keep track of last selected account user ID, because it's saved in localStorage
          // whereas selectedUserId is saved in sessionStorage
          lastSelectedUserId.value = selectedUserId.value
        })

        displaySettingsStore.fetchDisplaySettings()
        accountStore.fetchCurrentAccountSettingsPermissions()
        chatStore.$resetState()
      })
    }

    function checkAccountPermission(permission: AccountPermissionPaths) {
      return get(currentAccount.value?.permissions, permission) === true
    }

    // when a 401 is received for any endpoint,
    // signout the user and redirect to the signin page
    errorCatchers['401'].push(async () => {
      resetStoreStates()
      await router.push({ name: 'auth.signin' }).catch(() => {
        console.error('Error while redirecting to signin page')
      })
    })

    nextTick(() => {
      if (!selectedUserId.value) {
        // when opening app in a new tab, sessionStorage will be empty, so selectedUserId will be undefined
        // therefore, we need to set it to lastSelectedUserId, which comes from localStorage
        selectedUserId.value = lastSelectedUserId.value
      }

      if (currentUser.value) {
        nextTick(async () => {
          try {
            // fetch and apply display settings once store is created
            await displaySettingsStore.fetchDisplaySettings(abortController)
          } catch (e: any) {
            if (e.name !== 'AbortError') console.error(e.message)
          }
        })
      }
    })

    return {
      $resetState,

      authToken,
      accounts,
      user: currentUser,
      account: currentAccount,
      switchAccount,
      selectedUserId,
      lastSelectedUserId,
      registerUser,
      signinUser,
      signoutUser,
      resetUserPassword,
      updateUserPassword,
      getInvitedUser,
      joinUser,
      fetchUser,
      checkAccountPermission,
    }
  },
  {
    persist: [
      {
        paths: ['authToken', 'lastSelectedUserId'],
        storage: localStorage,
      },
      {
        paths: ['selectedUserId'],
        // sessionStorage allows us to have different selectedUserId for different tabs
        storage: sessionStorage,
      },
    ],
  }
)

export default authStore
