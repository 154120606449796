import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { flattenRecord, restructureRecord } from '@papershift/jsonapi/src/utils'
import * as api from '@papershift/api/src/chat'
import * as paginationApi from '@papershift/api/src/pagination'
import type { Role } from '@papershift/api/src/role'
import type { User } from '@papershift/api/src/user'
import { clearState } from '../utils'
import {
  setRecords,
  addIncluded,
} from '@papershift/jsonapi/src/records.mutations'
import type { JsonApiListResponse } from '@papershift/api/src/types'

type ChatMessageState = {
  messages: api.ChatMessageWithAuthor[]
  nextPageLink: string | null
}

const chatStore = defineStore('chat', () => {
  const state = {
    records: {
      chat_member: ref<api.ChatMember[]>([]),
      role: ref<Role[]>([]),
      user: ref<User[]>([]),
    },
    currentChat: ref<api.Chat | null>(null),
    messages: ref<Map<string, ChatMessageState>>(new Map()),
  }

  const currentChatMessages = computed(() => {
    if (!state.currentChat.value) return []
    return state.messages.value.get(state.currentChat.value.id)?.messages ?? []
  })

  function $resetState() {
    state.currentChat.value = null // TODO: clearState utility isn't working for this variable
    clearState(state)
  }

  async function fetchChatByContext(
    contextId: string,
    contextType: api.ChatContextType
  ) {
    const { data } = await api.listChatsByContext(contextId, contextType)

    state.currentChat.value = data.length
      ? flattenRecord<api.Chat>(data[0])
      : null
  }

  function closeCurrentChat() {
    state.currentChat.value = null
  }

  async function fetchMessagesByChat(chatId: string, replace: boolean = false) {
    const existingState = state.messages.value.get(chatId)

    let request: Promise<JsonApiListResponse<api.ChatMessageWithAuthor>>
    if (existingState === undefined || replace) {
      request = api.listMessagesByChat(chatId)
    } else if (existingState.nextPageLink) {
      request = paginationApi.listNextItems(existingState.nextPageLink)
    } else {
      return
    }

    const { data, included = [], links } = await request

    addIncluded(state, { records: included })

    const newMessages = data.map((chatMessage) =>
      restructureRecord(chatMessage, state)
    ) as api.ChatMessageWithAuthor[]

    // the "old" array is intentionally being reused here
    const messages = replace ? [] : existingState?.messages ?? []
    messages.push(...newMessages)

    state.messages.value.set(chatId, { messages, nextPageLink: links.next })
  }

  function createChatMessage(chatId: string, content: string) {
    return api.createChatMessage(chatId, content)
  }

  async function fetchChatMembersWithRoles(chatId: string) {
    const { data, included = [] } = await api.listChatMembers(chatId)

    setRecords(state, { type: 'chat_member', records: data })
    addIncluded(state, { records: included })
  }

  return {
    $resetState,

    currentChat: state.currentChat,
    currentChatMessages,
    messages: state.messages,
    chatMembers: state.records.chat_member,

    createChatMessage,
    fetchChatByContext,
    fetchMessagesByChat,
    fetchChatMembersWithRoles,
    closeCurrentChat,
  }
})

export default chatStore
