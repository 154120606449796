import type { User } from '@papershift/api/src/user'
import type { Account } from '@papershift/api/src/account'
import { extractIncluded } from '@papershift/jsonapi/src/utils'
import * as api from '@papershift/api/src/auth'

export type JoinResponse = {
  user: Partial<User>
  account: Partial<Account>
}

export default function useInvite() {
  async function getInvitedUser(inviteToken: string): Promise<JoinResponse> {
    const response = await api.getInvitedUser(inviteToken)
    const included = extractIncluded(response.included)

    return {
      user: { id: response.data.id, ...response.data.attributes },
      account: included.account[0].attributes,
    }
  }

  async function acceptInvite(
    inviteToken: string,
    email: string,
    password: string
  ): Promise<api.AuthResponse> {
    return api.acceptInvite(inviteToken, email, password)
  }

  return {
    getInvitedUser,
    acceptInvite,
  }
}
