import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { SetFieldType } from 'type-fest'
import useAuthStore from '../auth/auth.store'
import useRoleStore from '../role/role.store'
import * as api from '@papershift/api/src/user'
import {
  setRecords,
  addRecords,
  replaceRecord,
} from '@papershift/jsonapi/src/records.mutations'
import { flattenRecord, extractIncluded } from '@papershift/jsonapi/src/utils'
import { clearState } from '../utils'
import type { Role, AccountMembership } from '@papershift/api/src/role'
import type { JsonApiMeta, JsonApiIncluded } from '@papershift/api/src/types'
import {
  type FilterItem,
  FilterOperator,
} from '@papershift/api/src/filter-utils'

export type AccountMembershipWithRole = AccountMembership & { role: Role }
export type UserWithAccountRoles = SetFieldType<
  api.User,
  'account_memberships',
  AccountMembershipWithRole[]
>

const userStore = defineStore('user', () => {
  const authStore = useAuthStore()
  const roleStore = useRoleStore()

  const state = {
    records: {
      user: ref<api.User[]>([]),
      account_membership: ref<AccountMembershipWithRole[]>([]),
    },
    meta: {
      user: ref<JsonApiMeta>(),
    },
    profileUser: ref<api.ProfileUser>(),
    searchKeyword: ref<string>(''),
  }

  function $resetState() {
    clearState(state)
  }

  async function createUser(payload: Partial<api.User>) {
    const { data } = await api.createUser(payload)

    return flattenRecord(data) as api.User
  }

  async function updateUser(payload: Partial<api.User>) {
    if (typeof payload.id !== 'undefined') {
      const params: api.UpdateUserParams = {
        id: payload.id,
        ...payload,
      }
      await api.updateUser(params)
    }
  }

  async function fetchUsers(
    search = state.searchKeyword.value,
    page = state.meta.user.value?.current_page
  ) {
    state.searchKeyword.value = search

    const filters: FilterItem[] = []

    if (search) {
      filters.push({
        key: 'name',
        operator: FilterOperator.CT,
        value: search,
      })
    }

    const { data, meta } = await api.listUsers(filters, page)
    setRecords(state, { type: 'user', records: data })
    state.meta.user.value = meta
  }

  async function fetchAccountUsers(page = state.meta.user.value?.current_page) {
    const { data, included = [] } = await api.listUsersWithAccountRoles(page)

    setRecords(state, { type: 'user', records: data })
    addIncludedAccountMemberships(included)
  }

  async function getAvailableUsers(filters: FilterItem[]) {
    const { data } = await api.listAvailableUsers(filters)
    return data.map(flattenRecord) as api.User[]
  }

  async function getUser(userId: string): Promise<api.User> {
    const { data } = await api.getUser(userId)
    return flattenRecord(data) as api.User
  }

  async function getAccountUserRoles(userId: string): Promise<Role[]> {
    const { included = [] } = await api.getUserWithRolesInAccount(userId)

    return included.map(
      (membership) => roleStore.rolesObject[membership.attributes.role_id]
    )
  }

  async function deleteUser(userId: string) {
    await api.deleteUser(userId)
  }

  async function inviteUser(userId: string) {
    const { data } = await api.createInvite(userId)
    replaceRecord(state, {
      type: 'user',
      match: { id: data.id },
      replacement: data,
    })
  }

  async function revokeInvite(userId: string) {
    const { data } = await api.deleteInvite(userId)
    replaceRecord(state, {
      type: 'user',
      match: { id: data.id },
      replacement: data,
    })
  }

  async function fetchProfileUser(userId: string) {
    const currentUserId = authStore.user?.id
    let user

    if (userId === currentUserId) {
      user = authStore.user!
    } else {
      user = getUser(userId)
    }

    const [profileUser, { data: profilePermissions }] = await Promise.all([
      user,
      api.listProfilePermissions(userId),
    ])

    state.profileUser.value = {
      ...profileUser,
      profilePermissions: profilePermissions.attributes,
    }
  }

  async function addIncludedAccountMemberships(includedRaw: JsonApiIncluded) {
    const included = extractIncluded(includedRaw)

    if (included.account_membership) {
      if (!roleStore.roles.length) await roleStore.fetchRoles()

      addRecords(state, {
        type: 'account_membership',
        records: included.account_membership.map((membership) => {
          membership.attributes.role =
            roleStore.rolesObject[membership.attributes.role_id]
          return membership
        }),
      })
    }
  }

  return {
    $resetState,

    users: state.records.user,
    usersMeta: state.meta.user,
    searchKeyword: state.searchKeyword,
    profileUser: state.profileUser,

    createUser,
    updateUser,
    fetchUsers,
    fetchAccountUsers,
    getAvailableUsers,
    getUser,
    getAccountUserRoles,
    deleteUser,
    fetchProfileUser,

    inviteUser,
    revokeInvite,

    state,
  }
})

export default userStore
