import { onMounted, onUnmounted } from 'vue'
import type { TrackingDeclarations, StatusCollection } from './types'
import useActionStatusStore from './store'

type CustomProxyConstructor = new <T extends object, H extends object>(
  target: T,
  handler: ProxyHandler<T>
) => H

const CustomProxy = Proxy as CustomProxyConstructor

export default function useActionTracker(declarations: TrackingDeclarations) {
  const actionStatusStore = useActionStatusStore()

  const handler = {
    get(declarations: TrackingDeclarations, prop: string) {
      return Reflect.get(actionStatusStore.actionsGetter(declarations), prop)
    },
  }

  onMounted(() => {
    actionStatusStore.whitelistActions(declarations)
  })

  onUnmounted(() => {
    actionStatusStore.removeActions(declarations)
  })

  return new CustomProxy<
    TrackingDeclarations,
    // TODO: support MultiInstanceStatusCollection as well
    StatusCollection
  >(declarations, handler)
}
