import { createI18n, useI18n, I18nT } from 'vue-i18n'
import { computed } from 'vue'
import globalMessages from './global-messages'
import { useNavigatorLanguage } from '@vueuse/core'

export type LocaleCode = 'en' | 'de'

export type Locale = {
  code: LocaleCode
  name: string
  flag: string
}

export const LOCALES: Locale[] = [
  {
    code: 'en',
    name: 'English',
    flag: 'https://flagcdn.com/gb.svg',
  },
  {
    code: 'de',
    name: 'Deutsch',
    flag: 'https://flagcdn.com/de.svg',
  },
]

const { language } = useNavigatorLanguage()
const FALLBACK_LOCALE = LOCALES[0]

const i18n = createI18n({
  legacy: false,
  locale: language.value?.split('-')[0] ?? FALLBACK_LOCALE.code,
  fallbackLocale: FALLBACK_LOCALE.code,
  messages: globalMessages,
})

export function addMessages(messages: Record<LocaleCode, Record<string, any>>) {
  for (const locale in messages) {
    i18n.global.mergeLocaleMessage(locale, messages[locale as LocaleCode])
  }
}

export function switchLocale(locale: LocaleCode) {
  i18n.global.locale.value = locale
}

export const currentLocale = computed(
  () =>
    LOCALES.find((locale) => locale.code === i18n.global.locale.value) ??
    FALLBACK_LOCALE
)

// @ts-ignore exposed for easier lang switch in tests
globalThis.switchLocale = (locale) => {
  switchLocale(locale)
}

export { useI18n, I18nT }
export default i18n
