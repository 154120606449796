import type { State, RecordGeneric } from './types'
import { getRef, restructureRecord, extractIncluded } from './utils'
import { reindexSingle, reindexMultiple, clearIndex } from './indexing'
import { replaceRecord } from './_replace.records.mutations'

export { replaceRecord } from './_replace.records.mutations'
export { deleteRecords } from './_delete.records.mutations'

export function addRecords(
  state: State,
  { type, records }: { type: string; records: RecordGeneric[] }
) {
  getRef(state, type)

  const toAppend = []

  for (const r of records) {
    const index = replaceRecord(state, {
      type,
      match: r,
      replacement: r,
    })

    if (index === -1) {
      toAppend.push(r)
    }
  }

  appendRecords(state, { type, records: toAppend })
}

// sets given records by overriding current ones. Also runs indexing of data
export function setRecords(
  state: State,
  { type, records }: { type: string; records: RecordGeneric[] }
) {
  getRef(state, type).value = records.map((r: RecordGeneric, index: number) => {
    reindexSingle(state, type, r.id, index)
    return restructureRecord(r, state)
  })
}

// add records to the beginning of record array in the state. Also takes care of
// re-indexing all records. appendRecords() is preferred over this, because
// prepending resets indexes of all existing records in state
export function prependRecords(
  state: State,
  { type, records }: { type: string; records: RecordGeneric[] }
) {
  const stateRecords = getRef(state, type)

  for (const r of records) {
    stateRecords.value.unshift(restructureRecord(r, state))
  }
  reindexMultiple(state, type, 0)
}

// adds records to the end of record array in the state. Also runs reindexing
export function appendRecords(
  state: State,
  { type, records }: { type: string; records: RecordGeneric[] }
) {
  const stateRecords = getRef(state, type)

  for (const r of records) {
    const record = restructureRecord(r, state)
    const newLength = stateRecords.value.push(record)
    reindexSingle(state, type, record.id, newLength - 1)
  }
}

// assigns given record type to empty array
export function clearRecords(state: State, { type }: { type: string }) {
  const stateRecords = getRef(state, type)

  stateRecords.value = []
  clearIndex(state, type)
}

// helper function that extracts jsonapi `included` records and runs
// addRecords() on each record type
export function addIncluded(
  state: State,
  { records }: { records: RecordGeneric[] }
) {
  const obj = extractIncluded(records)

  Object.keys(obj).forEach((type) => {
    addRecords(state, { type, records: obj[type] })
  })
}

// same as addIncluded(), but runs setRecords() instead of addRecords()
export function setIncluded(
  state: State,
  { records }: { records: RecordGeneric[] }
) {
  const obj = extractIncluded(records)

  Object.keys(obj).forEach((type) => {
    setRecords(state, { type, records: obj[type] })
  })
}
