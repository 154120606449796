import api from './api'
import AbortAddon from 'wretch/addons/abort'
import type { LocaleCode } from '@papershift/locale/src/i18n'
import type { DateFormatValue } from '@papershift/locale/src/date-format'
import type { JsonApiResponse } from './types'

export type DisplaySettings = {
  id: string
  date_format: DateFormatValue
  language: LocaleCode
}

export function getDisplaySettings(
  userId: string,
  abortController?: AbortController
): Promise<JsonApiResponse<DisplaySettings>> {
  const url = `/api/v1/users/${userId}/display_settings`

  if (abortController) {
    return api.url(url).addon(AbortAddon()).signal(abortController).get().json()
  } else {
    return api.url(url).get().json()
  }
}

export function updateDisplaySettings(
  userId: string,
  params: Partial<DisplaySettings>
) {
  return api
    .url(`/api/v1/users/${userId}/display_settings`)
    .put({
      data: {
        type: 'display_settings',
        attributes: params,
      },
    })
    .json()
}

export function getAccountDisplaySettings(): Promise<
  JsonApiResponse<DisplaySettings>
> {
  return api.url(`/api/v1/display_settings`).get().json()
}

export function updateAccountDisplaySettings(params: Partial<DisplaySettings>) {
  return api
    .url(`/api/v1/display_settings`)
    .put({
      data: {
        type: 'display_settings',
        attributes: params,
      },
    })
    .json()
}
