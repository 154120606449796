import wretch, { type ConfiguredMiddleware } from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import i18n, { addMessages, currentLocale } from '@papershift/locale/src/i18n'
import { showLoading, hideLoading } from '@papershift/loading/src/loading'
import { notify } from '@papershift/ui/src/Notifier'

const { t } = i18n.global

addMessages({
  en: {
    catch_403_title: 'Error',
    catch_403_message: 'Permission error occurred',
    catch_500_title: 'Error',
    catch_500_message: 'Something went wrong',
  },
  de: {
    catch_403_title: 'Fehler',
    catch_403_message: 'Fehlende Berechtigung',
    catch_500_title: 'Fehler',
    catch_500_message: 'Hier ist etwas schief gelaufen',
  },
})

type Catcher = () => void | Promise<void>
export const errorCatchers: Record<string, Catcher[]> = {
  '401': [],
}

const headers = {
  'Content-Type': 'application/vnd.api+json',
}
const localeHeaderMiddleware: ConfiguredMiddleware =
  (next) => (url, options) => {
    options.headers['Accept-Language'] = currentLocale.value.code

    return next(url, options)
  }

const authHeaderMiddleware: ConfiguredMiddleware = (next) => (url, options) => {
  try {
    const authState = JSON.parse(localStorage.getItem('auth') ?? '{}')
    const authSessionState = JSON.parse(sessionStorage.getItem('auth') ?? '{}')

    if (authState?.authToken) {
      options.headers['Authorization'] = authState.authToken
      options.headers['User-Id'] = authSessionState.selectedUserId
    }
  } catch (e) {
    console.error('Error parsing "auth" from localStorage or sessionStorage', e)
  }

  return next(url, options)
}

const loadingMiddleware: ConfiguredMiddleware =
  (next) => async (url, options) => {
    showLoading()
    const response = await next(url, options)

    hideLoading()
    return response
  }

const api = wretch(import.meta.env.VITE_API_URL)
  .addon(QueryStringAddon)
  .headers(headers)
  .middlewares([
    authHeaderMiddleware,
    localeHeaderMiddleware,
    loadingMiddleware,
  ])
  .catcher(401, async () => {
    for (const handler of errorCatchers['401']) {
      try {
        await handler()
      } catch {
        console.error('Error in 401 error catcher')
      }
    }
  })
  .catcher(403, (error) => {
    const message = error.json?.errors?.[0]?.title || t('catch_403_message')

    notify({
      type: 'error',
      title: t('catch_403_title'),
      message,
    })
    throw new Error(`Permission error occurred: ${message}`)
  })
  .catcher(500, () => {
    notify({
      type: 'error',
      title: t('catch_500_title'),
      message: t('catch_500_message'),
    })
    throw new Error('Internal Server Error')
  })

export default api
