export default function useValidationMessages() {
  function getUnregisteredFieldsMessages(
    registeredFields: Record<string, any>,
    validationMessages: Map<string, string>
  ) {
    const messages: string[] = []

    validationMessages.forEach((message, field) => {
      if (!(field in registeredFields)) {
        messages.push(message)
      }
    })

    return messages.join('. ')
  }

  return { getUnregisteredFieldsMessages }
}
