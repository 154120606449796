<script setup lang="ts">
import { computed, ref } from 'vue'
import type { Option } from '@papershift/ui/src/Select.vue'
import { useI18n } from '@papershift/locale/src/i18n'
import useActionTracker from '@papershift/action-status/src/action-tracker'
import useUserSearch from '@/components/composables/use-user-search'
import useRoleStore from '@/stores/role/role.store'

const props = defineProps<{
  chatId: string
}>()

const emit = defineEmits<{
  added: []
}>()

const { t } = useI18n()

const $actions = useActionTracker({
  createChatMembership: 'createChatMembership',
})

const {
  searchUsers,
  selectedUser,
  setSelectedUser,
  users,
  searchActionStatus,
  removeSelectedUser,
} = useUserSearch()

const roleStore = useRoleStore()

const roles = computed(() =>
  roleStore.chatRoles.map((role) => ({
    label: role.name,
    value: role.id,
  }))
)
const selectedRoles = ref<Option[]>([])
const isInputValid = computed(
  () => !selectedUser.value || !selectedRoles.value.length
)

async function submit() {
  await roleStore.createChatMembership(
    props.chatId,
    selectedUser.value!.value!,

    selectedRoles.value.map((role) => role.value) as [string, ...string[]]
  )

  await removeSelectedUser()
  selectedRoles.value = []

  emit('added')
}

roleStore.fetchRoles()
</script>

<template>
  <Form
    :submit-handler="submit"
    :action-status="$actions.createChatMembership"
    class="w-full"
  >
    <!-- Flexbox container for horizontal alignment -->
    <div class="flex flex-row gap-4 w-full items-center">
      <!-- User Search (Autocomplete) -->
      <div class="flex-1 min-w-0">
        <Autocomplete
          id="chat_member_add_user_search"
          label=""
          :placeholder="t('search_users')"
          :tabindex="1"
          :options="users"
          :model-value="selectedUser"
          :action-status="searchActionStatus"
          @update:model-value="setSelectedUser"
          @input="searchUsers"
          class="w-full"
        />
      </div>

      <!-- Role Selection (Select) -->
      <div class="flex-1 min-w-0">
        <Select
          id="chat_member_add_role_select"
          label=""
          :multiple="true"
          :options="roles"
          :tabindex="2"
          :placeholder="t('select_roles')"
          v-model="selectedRoles"
          class="w-full"
        />
      </div>

      <!-- Submit Button -->
      <div class="flex-shrink-0">
        <Button
          id="chat_member_add_submit"
          appearance="primary"
          :label="t('submit')"
          :tabindex="3"
          :disabled="isInputValid"
          type="submit"
          class="h-9 mt-1 w-full"
        />
      </div>
    </div>
  </Form>
</template>

/* v8 ignore start */
<i18n locale="en">
search_users: Employee
select_roles: Roles
submit: Add
</i18n>

<i18n locale="de">
search_users: Mitarbeiter
select_roles: Rollen
submit: Hinzufügen
</i18n>
/* v8 ignore stop */
