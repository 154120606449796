import { createApp, h, ref } from 'vue'
import i18n from '@papershift/locale/src/i18n'
import Loading from './Loading.vue'
import throttle from 'lodash/throttle'

const isActive = ref(false)
const activate = throttle(() => (isActive.value = true), 700, {
  leading: false,
  trailing: true,
})

export function showLoading() {
  setup()
  activate()
}

export function hideLoading() {
  isActive.value = false
  activate.cancel()
}

function setup() {
  if (document.getElementById('loading')) return

  const elem = document.createElement('div')
  elem.setAttribute('id', 'loading')
  document.body.appendChild(elem)

  const app = createApp({
    render: () => h(Loading, { isActive: isActive.value }),
  })

  app.use(i18n)
  app.mount(elem)
}
