<script setup lang="ts">
import i18n, { addMessages, I18nT } from '@papershift/locale/src/i18n'

const { t } = i18n.global

defineProps<{
  isActive: boolean
}>()

addMessages({
  en: {
    reload: {
      text: 'Got stuck? Try {0} the page.',
      link: 'reloading',
    },
  },
  de: {
    reload: {
      text: 'Es geht nicht weiter? Lade {0} die Seite neu.',
      link: 'jetzt',
    },
  },
})

function reloadPage() {
  window.location.reload()
}
</script>

<template>
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isActive"
      class="fixed inset-0 bg-neutral-50 bg-opacity-50 z-50 flex flex-col justify-between"
    >
      <div class="flex items-center justify-center h-full">
        <div
          class="animate-spin rounded-full h-16 w-16 sm:h-24 sm:w-24 border-t-2 border-gray-400"
        ></div>
      </div>

      <span class="text-center text-gray-600 mb-20">
        <i18n-t keypath="reload.text">
          <button
            @click="reloadPage"
            class="font-medium text-pink-600 hover:text-pink-500"
          >
            {{ t('reload.link') }}
          </button>
        </i18n-t>
      </span>
    </div>
  </transition>
</template>
