import { type Ref, computed } from 'vue'
import type { State, RecordGeneric, ResourceLink } from './types'
import { isRecordTypeIndexed, getRecordIndex } from './indexing'

// defines custom getters for each relationship of given record. The getter
// tries to read related records from store. For example, time tracking notes
// will be read from state.records.notes.
// > const record = {id: '1', type: 'type', _relationships: {rel: {data: {id: '12'}}}}
// > linkRelationship(state, record, 'rel')
// `record.rel` is now reference to a record under state.records.rel with ID 12.
// when records are one-to-many, `record.rel` will equal to array of references.
export function linkRelationship(
  state: State,
  record: RecordGeneric,
  relName: string
) {
  record[relName] = computed(() => {
    const resLink = record._relationships[relName].data

    if (Array.isArray(resLink)) {
      let results: RecordGeneric[] = []

      if (resLink.length) {
        const type = resLink[0]?.type
        const relRecords = state.records[type]

        if (relRecords) {
          results = getReslinksIndexes(state, type, resLink, relRecords).map(
            (index: number) => relRecords.value[index]
          )
        }
      }
      return results
    } else if (resLink != null) {
      const type = resLink.type
      const relRecords = state.records[type]
      let result = null

      if (relRecords) {
        const isIndexed = isRecordTypeIndexed(state, type)

        if (isIndexed) {
          const index = getRecordIndex(state, type, resLink.id)
          result = relRecords.value[index]
        } else {
          result = relRecords.value.find((relItem) => relItem.id === resLink.id)
        }
      }
      return result
    }

    return null
  })
}

// internal function used by linkRelationship() util to get array of indexes of
// related records via `resLinks`
function getReslinksIndexes(
  state: State,
  type: string,
  resLinks: ResourceLink[],
  relRecords: Ref<RecordGeneric[]>
): number[] {
  const isIndexed = isRecordTypeIndexed(state, type)
  const indexes = []

  for (const { id } of resLinks) {
    let index = -1

    if (isIndexed) {
      index = getRecordIndex(state, type, id)
    } else {
      index = relRecords.value.findIndex((relItem) => relItem.id === id)
    }
    if (index !== -1) {
      indexes.push(index)
    }
  }

  return indexes.sort((a, b) => a - b)
}
