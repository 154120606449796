import type { SetOptional } from 'type-fest'
import api from './api'
import type { FieldGroupPayload } from './field'

type TemplateResponse<T> = {
  data: T[]
}

type TemplateField = Omit<
  SetOptional<Required<FieldGroupPayload['fields'][0]>, 'value_restrictions'>,
  'id' | '_delete'
>

export type FieldGroupTemplate = {
  id: string
  data: {
    type: 'field_group'
    attributes: Omit<FieldGroupPayload, 'fields'> & {
      fields: TemplateField[]
    }
  }
  meta: Record<string, any>
}

export async function listFieldGroupTemplates(): Promise<
  TemplateResponse<FieldGroupTemplate>
> {
  return api.url('/api/v1/templates?type=field_group').get().json()
}

export type RoleTemplate = {
  id: string
  data: {
    type: 'role'
    attributes: {
      name: string
      description: string
      scope: 'team'
    }
  }
  meta: Record<string, any>
}

export async function listRoleTemplates(): Promise<
  TemplateResponse<RoleTemplate>
> {
  return api.url('/api/v1/templates?type=role').get().json()
}
