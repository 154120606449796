import { isReadonly, isRef, ref } from 'vue'
import isPlainObject from 'lodash/isPlainObject'
import type { State } from '@papershift/jsonapi/src/types'
import { clearRecords } from '@papershift/jsonapi/src/records.mutations'

// clears given state object by going through each object Vue Ref item.
// "clearing" means setting items to "empty values" (0, false, '', [], {}, etc.)
// when jsonapi `records` are present, it uses `clearRecords()` to clear them instead.
// it also recursively goes through the nested objects.
// non-Ref items are returned as-is.
export function clearState(state: Record<string, any>) {
  for (const [key, value] of Object.entries(state)) {
    if (key === 'records') {
      for (const recordType of Object.keys(value)) {
        if (!isReadonly(value[recordType])) {
          clearRecords(state as State, { type: recordType })
        }
      }
    } else {
      state[key] = makeEmptyRefsDeep(state, key)
    }
  }
}

function makeEmptyRefsDeep(state: Record<string, any>, key: string): any {
  const value = state[key]

  if (isRef(value)) {
    if (Array.isArray(value.value)) {
      return ref([])
    } else if (value.value instanceof Map) {
      return ref(new Map())
    } else if (value.value instanceof Set) {
      return ref(new Set())
    } else if (isPlainObject(value.value)) {
      return ref({})
    } else {
      switch (typeof value.value) {
        case 'string':
          return ref('')
        case 'number':
          return ref(0)
        case 'boolean':
          return ref(false)
        default:
          return value
      }
    }
  } else if (isPlainObject(value)) {
    for (const key of Object.keys(value)) {
      value[key] = makeEmptyRefsDeep(value, key)
    }
  }
  return value
}
