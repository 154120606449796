import type { TrackingDeclarations, TrackingDeclarationValue } from '../types'

export default function useCollectionMaker(
  actions: TrackingDeclarations
): TrackingDeclarationValue[] {
  const items = []

  for (const value of Object.values(actions)) {
    if (typeof value === 'string') {
      items.push({ action: value })
    } else {
      items.push(value)
    }
  }
  return items
}
